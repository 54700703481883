import React, { useEffect, useState } from "react";
import Card from './components/cardDisplay';
import InputField from '../../../components/fields/InputField';
import apiClient from './../axiosInstance';
import Modal from '../../../components/Modal/Modal';
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

const PricesOverview = () => {
    const [cars, setCars] = useState([]);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({});
    const [originalPrices, setOriginalPrices] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: '',
        footer: null,
    });

    useEffect(() => {
        const fetchCars = async () => {
            try {
                const response = await apiClient.get('/cars-with-prices');

                const data = response.data;
                setCars(data);

                const prices = {};
                data.forEach((car) => {
                    prices[car.CarID] = {};
                    for (const month of [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                    ]) {
                        prices[car.CarID][month] = car[month];
                    }
                });
                setOriginalPrices(prices);
            } catch (err) {
                console.error('Error fetching cars:', err);
                setError(err.message);
            }
        };

        fetchCars();
    }, []);

    const handleInputChange = (carId, month, value) => {
        const numericValue = parseFloat(value);
        const originalValue = originalPrices[carId]?.[month];

        if (value === originalValue.toString()) {
            setFormData((prevData) => ({
                ...prevData,
                [carId]: {
                    ...prevData[carId],
                    [month]: originalValue,
                },
            }));
        } else {
            if (value === "" || numericValue > 0) {
                setFormData((prevData) => ({
                    ...prevData,
                    [carId]: {
                        ...prevData[carId],
                        [month]: value === "" ? undefined : value,
                    },
                }));
            }
        }
    };

    const handleSave = async (carId) => {
        const updatedPrices = {
            CarID: carId,
            January: formData[carId]?.January !== undefined ? formData[carId].January : originalPrices[carId].January,
            February: formData[carId]?.Ferbruary !== undefined ? formData[carId].February : originalPrices[carId].February,
            March: formData[carId]?.March !== undefined ? formData[carId].March : originalPrices[carId].March,
            April: formData[carId]?.April !== undefined ? formData[carId].April : originalPrices[carId].April,
            May: formData[carId]?.May !== undefined ? formData[carId].May : originalPrices[carId].May,
            June: formData[carId]?.June !== undefined ? formData[carId].June : originalPrices[carId].June,
            July: formData[carId]?.July !== undefined ? formData[carId].July : originalPrices[carId].July,
            August: formData[carId]?.August !== undefined ? formData[carId].August : originalPrices[carId].August,
            September: formData[carId]?.September !== undefined ? formData[carId].September : originalPrices[carId].September,
            October: formData[carId]?.October !== undefined ? formData[carId].October : originalPrices[carId].October,
            November: formData[carId]?.November !== undefined ? formData[carId].November : originalPrices[carId].November,
            December: formData[carId]?.December !== undefined ? formData[carId].December : originalPrices[carId].December,
        };

        // Check if there are changes
        const hasChanges = Object.keys(updatedPrices).some(
            (key) => key !== 'CarID' && updatedPrices[key] !== originalPrices[carId][key]
        );

        if (!hasChanges) {
            // Show info modal
            setModalContent({
                title: 'Info',
                content: 'No changes to save for this car.',
                footer: (
                    <div className="flex justify-end w-full">
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                ),
            });
            setIsModalOpen(true);
            return;
        }

        try {
            await apiClient.put(`/update-prices/${carId}`, updatedPrices);

            setModalContent({
                title: 'Success',
                content: (
                    <div className="flex flex-col items-center">
                        <CheckCircleIcon className="h-12 w-12 text-green-500 mb-4" />
                        <p>Prices updated successfully.</p>
                    </div>
                ),
                footer: (
                    <div className="flex justify-end w-full">
                        <button
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                ),
            });
            setIsModalOpen(true);

            // Update original prices and cars state
            setOriginalPrices((prevPrices) => ({
                ...prevPrices,
                [carId]: updatedPrices,
            }));
            setCars((prevCars) =>
                prevCars.map((car) => (car.CarID === carId ? { ...car, ...updatedPrices } : car))
            );
        } catch (error) {
            console.error('Error updating prices:', error.response ? error.response.data : error.message);

            setModalContent({
                title: 'Error',
                content: (
                    <div className="flex flex-col items-center">
                        <XMarkIcon className="h-12 w-12 text-red-500 mb-4" />
                        <p>Failed to update prices. Please try again.</p>
                    </div>
                ),
                footer: (
                    <div className="flex justify-end w-full">
                        <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                ),
            });
            setIsModalOpen(true);
        }
    };

    return (
        <div className="flex w-full flex-col gap-5">
            <div className="mt-5 grid grid-cols-1 gap-5">
                {error ? (
                    <p className="text-red-500">Error: {error}</p>
                ) : (
                    cars.map((car) => (
                        <Card key={car.CarID} extra="p-5 mb-1 flex h-auto">
                            <div className="flex flex-col md:flex-row w-full">
                                <div className="w-full md:w-1/2 h-auto flex-shrink-0 overflow-hidden">
                                    <div className="flex flex-col items-center">
                                        <span className="block font-bold text-center">
                                            {car.Make} {car.Model} {car.Year}
                                        </span>
                                        <span className="text-center">
                                            {car.Transmission} {car.FuelType}
                                        </span>
                                    </div>

                                    <img
                                        src={car.imageURL}
                                        alt={`${car.Make} ${car.Model}`}
                                        className="w-full h-auto"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </div>
                                <div className="w-full md:w-1/2 h-full flex flex-col justify-center items-center md:items-start mt-5 md:mt-0">
                                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 w-full">
                                        {[
                                            "January",
                                            "February",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December",
                                        ].map((month) => (
                                            <div key={month} className="flex flex-col items-center mb-2">
                                                <InputField
                                                    label={month}
                                                    placeholder={`${month} Price`}
                                                    value={
                                                        formData[car.CarID]?.[month] !== undefined
                                                            ? formData[car.CarID][month]
                                                            : car[month]
                                                    }
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        handleInputChange(car.CarID, month, newValue);
                                                    }}
                                                    type="number"
                                                    step="0.01"
                                                    extra={`w-20 text-center ${formData[car.CarID]?.[month] !== undefined &&
                                                            formData[car.CarID]?.[month] !==
                                                            originalPrices[car.CarID][month]
                                                            ? 'border-red-600'
                                                            : 'border-gray-300'
                                                        }`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <button
                                        className="bg-brand-500 text-white px-4 py-2 mt-4 rounded-lg hover:bg-brand-600 self-center md:self-end shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                                        onClick={() => handleSave(car.CarID)}
                                    >
                                        Save Prices
                                    </button>
                                </div>
                            </div>
                        </Card>
                    ))
                )}
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
            />
        </div>
    );
};

export default PricesOverview;
