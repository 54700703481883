import React from "react";
import InputField from './Fields';

const ReservationFormStepTwo = ({ reservationDetails, handleChange, error }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <InputField
            label="First Name"
            type="text"
            name="name"
            value={reservationDetails.name}
            onChange={handleChange}
            className=" border-gray-200 max-w-xs lg:flex-center"
            placeholder="    First Name"
        />
        <InputField
            label="Last Name"
            type="text"
            name="surname"
            value={reservationDetails.surname}
            onChange={handleChange}
            className=" border-gray-200 max-w-xs lg:flex-center"
            placeholder="    Last Name"
        />
        <InputField
            label="Email address"
            type="email"
            name="email"
            value={reservationDetails.email}
            onChange={handleChange}
            className=" border-gray-200 max-w-xs lg:flex-center"
            placeholder="     Email address"
        />
        <InputField
            label="Phone number"
            type="tel"
            name="phone"
            value={reservationDetails.phone}
            onChange={handleChange}
            className=" border-gray-200 max-w-xs lg:flex-center"
            placeholder="    Phone number"
        />
        <InputField
            label="Flight"
            type="text"
            name="flight"
            value={reservationDetails.flight}
            onChange={handleChange}
            className=" border-gray-200 max-w-xs lg:flex-center"
            placeholder="    Flight No"
        />
        <div className="flex gap-4">
            <InputField
                label="Price"
                type="number"
                name="price"
                value={reservationDetails.price}
                onChange={handleChange}
                className="border-gray-200 max-w-[8rem] lg:flex-center"
            />
            <InputField
                label="Age"
                type="number"
                name="age"
                value={reservationDetails.age}
                onChange={handleChange}
                className="border-gray-200 max-w-[8rem] lg:flex-center"
            />
        </div>

    </div>
);

export default ReservationFormStepTwo;
