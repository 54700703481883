import React, { useEffect } from 'react';

function useOutsideAlerter(ref, setOpenWrapper) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpenWrapper(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setOpenWrapper]);
}

const Dropdown = ({ buttonLabel, children, classNames = '' }) => {
    const wrapperRef = React.useRef(null);
    const [openWrapper, setOpenWrapper] = React.useState(false);

    useOutsideAlerter(wrapperRef, setOpenWrapper);

    const toggleDropdown = () => {
        setOpenWrapper((prev) => !prev);
    };

    return (
        <div ref={wrapperRef} className="relative inline-block text-left">
            <button
                onClick={toggleDropdown}
                className="border rounded-md p-1 bg-white dark:bg-navy-800 text-gray-800 dark:text-gray-200 border-gray-300 dark:border-gray-600"
            >
                {buttonLabel}
            </button>
            {openWrapper && (
                <div
                    className={`absolute z-10 mt-1 border rounded-md bg-white dark:bg-navy-800 text-gray-800 dark:text-gray-200 border-gray-300 dark:border-gray-600 shadow-lg ${classNames}`}
                    style={{ minWidth: '12rem' }}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
