import apiClient from './../../../axiosInstance';

export const fetchExtras = async (reservationID) => {
    try {
        const response = await apiClient.get('/ReservationExtras', {
            params: { ReservationID: reservationID }
        });

        return response.data;
    } catch (err) {
        console.error('Error fetching extras:', err.response ? err.response.data : err.message);
        return null;
    }
};
