import { MdCheckCircle, MdCancel, MdOutlineError } from 'react-icons/md';

const StatusIndicator = ({ status }) => {
    let icon, textClass;

    switch (status) {
        case "Confirmed":
            icon = <MdCheckCircle className="text-green-500 me-1" />;
            textClass = "text-green-500";
            break;
        case "Cancelled":
            icon = <MdCancel className="text-red-500 me-1" />;
            textClass = "text-red-500";
            break;
        case "History":
            icon = <MdOutlineError className="text-amber-600 me-1 dark:text-amber-400" />;
            textClass = "text-amber-600 dark:text-amber-400";
            break;
        default:
            textClass = "text-gray-800 dark:text-gray-200";
    }


    return (
        <div className="flex items-center">
            {icon}
            <p className={`text-sm font-bold ${textClass}`}>{status}</p>
        </div>
    );
};

export default StatusIndicator;
