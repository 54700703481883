// ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ element }) => {
    const { user, loading } = useAuth();

    // Wait for authentication check to complete
    if (loading) {
        return <div>Loading...</div>;
    }

    // If user is authenticated, render the element
    // Otherwise, redirect to sign-in
    return user ? element : <Navigate to="/auth/sign-in" replace />;
};

export default ProtectedRoute;
