import React from 'react';

const PaginationControls = ({ currentPage, setCurrentPage, pageCount }) => {
    return (
        <div className="flex items-center justify-between mt-2 mb-4">
            <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                disabled={currentPage === 0}
                className={`px-4 py-2 rounded-lg text-white transition duration-200 ease-in-out 
                ${currentPage === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-brand-600 hover:bg-brand-700'}`}
            >
                Previous
            </button>
            <p className="text-sm text-gray-700 mt-4">
                Page {currentPage + 1} of {pageCount}
            </p>
            <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount - 1))}
                disabled={currentPage === pageCount - 1}
                className={`px-4 py-2 rounded-lg text-white transition duration-200 ease-in-out 
                ${currentPage === pageCount - 1 ? 'bg-gray-400 cursor-not-allowed' : 'bg-brand-600 hover:bg-brand-700'}`}
            >
                Next
            </button>
        </div>
    );
};

export default PaginationControls;
