import React, { useEffect, useState } from "react";
import Card from './components/cardDisplay';
import apiClient from './../axiosInstance';
import InputField from '../../../components/fields/InputField';

const PricesOverview = () => {
    const [company, setCompany] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/company');
                const data = response.data.company;
                setCompany(data);
            } catch (err) {
                console.error('Error fetching company data:', err);
                setError(err.message);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompany((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <div className="flex w-full flex-col ">

            <div className="mt-5">
                {error ? (
                    <p className="text-red-500">Error: {error}</p>
                ) : (
                    company && (
                        <Card extra="p-5 mb-1 flex h-auto">
                                <div className="flex flex-col items-center md:items-start w-full gap-5 md:grid md:grid-cols-2 lg:grid-cols-3">

                                <InputField
                                    label="Company Name"
                                    name="CompanyName"
                                    value={company.CompanyName || ''}
                                    onChange={handleInputChange}
                                />

                                <InputField
                                    label="Address"
                                    name="Address"
                                    value={company.Address || ''}
                                    onChange={handleInputChange}
                                />

                                <InputField
                                    label="Phone Number"
                                    name="PhoneNumber"
                                    value={company.PhoneNumber || ''}
                                    onChange={handleInputChange}
                                />

                                <InputField
                                    label="Email"
                                    name="Email"
                                    value={company.Email || ''}
                                    onChange={handleInputChange}
                                />

                                <InputField
                                        label="Grace Period (minutes)"
                                        name="GracePeriodMinutes"
                                        type="number"
                                        value={company.GracePeriodMinutes || '0'}
                                        onChange={handleInputChange}
                                        placeholder='zero by default'
                                />

                                <InputField
                                    label="Out of Hours Cost (EURO)"
                                    name="CostOOH"
                                    type="number"
                                    value={company.CostOOH || ''}
                                    onChange={handleInputChange}
                                />

                                <InputField
                                    label="Booking Allowed After (minutes)"
                                    name="BookAfterMinutes"
                                    type="number"
                                    value={company.BookAfterMinutes || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Card>
                    )
                )}
            </div>
        </div>
    );
};

export default PricesOverview;
