import { FaExclamationCircle, FaBan } from "react-icons/fa";
import React from 'react';
import apiClient from './../../../axiosInstance';

async function cancelReservation(reservationId) {
    try {
        const response = await apiClient.put(`/cancel/${reservationId}`);
        return response.data.reservation;
    } catch (error) {
        if (error.response) {
            console.error('Error:', error.response.data.message);
        } else {
            console.error('Error:', error.message);
        }
    }
}

export const checkCancel = async (row, setIsModalOpen, setModalContent) => {
    try {
        if (row.Status === "Cancelled") {
            setModalContent({
                title: "Cancelled",
                content: "Reservation is already cancelled.",
                headerIcons: [FaExclamationCircle],
            });
            setIsModalOpen(true);
        } else if (row.Channel === "Site" || row.Channel === "Broker") {
            setModalContent({
                title: "Not Authorised",
                content: "Cannot cancel a reservation that we created for you.",
                headerIcons: [FaBan],
            });
            setIsModalOpen(true);
        } else {
            setModalContent({
                title: "This action will cancel your reservation. Do you want to proceed?",
                content: null,
                footer: (
                    <div className="flex justify-between w-full">
                        <button
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                            onClick={async () => {
                                await cancelReservation(row.ReservationID);
                                setIsModalOpen(false);
                                window.location.reload();
                            }}
                        >
                            Yes Cancel
                        </button>
                        <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                            onClick={() => setIsModalOpen(false)}
                        >
                            No
                        </button>
                    </div>
                ),
                headerIcons: [],
            });
            setIsModalOpen(true);
        }
    } catch (err) {
        console.log(err.message);
        return null;
    }
};
