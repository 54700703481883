import React, { useEffect, useState } from 'react';
import ReservationTable from './components/ReservationTable/ReservationTable';
import apiClient from './../axiosInstance';

const Reservations = () => {
    const [, setLoading] = useState(true);
    const [reservationsData, setReservationsData] = useState(null);
    const [stationsData, setStationsData] = useState(null);
    const [carsData, setCarsData] = useState(null);
    const [columns, setColumns] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/reservations-stations-cars');

                const result = response.data;
                setReservationsData(result.reservations);
                setStationsData(result.stations);
                setCarsData(result.cars);

                // Set columns based on the reservations data
                if (result.reservations && result.reservations.length > 0) {
                    const columnNames = Object.keys(result.reservations[0]).map((key) => ({
                        accessor: key,
                        Header: key.charAt(0).toUpperCase() + key.slice(1)
                    }));
                    setColumns(columnNames);
                }
            } catch (err) {
                console.log(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="w-full">
            <div className="mt-5 mb-5 grid h-full gap-3">
                {columns && reservationsData && (
                    <ReservationTable
                        columnsData={columns}
                        tableData={reservationsData}
                        stationsData={stationsData}
                        carsData={carsData}
                    />
                )}
            </div>
        </div>
    );
};

export default Reservations;
