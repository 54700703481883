import { useState } from 'react';
import axios from 'axios';
import InputField from "components/fields/InputField";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email });
            setMessage('Password reset email sent. Please check your inbox.');
            setEmail('');
        } catch (error) {
            setError(error.response?.data?.message || 'Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Forgot Password
                </h4>

                <div className="mb-6 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                </div>

                {/* Success Message */}
                {message && (
                    <div
                        className="mb-4 text-green-500 text-sm"
                        aria-live="polite"
                    >
                        {message}
                    </div>
                )}

                {/* Error Message */}
                {error && (
                    <div
                        className="mb-4 text-red-500 text-sm"
                        aria-live="polite"
                    >
                        {error}
                    </div>
                )}

                <form onSubmit={handleForgotPassword}>
                    <InputField
                        className="mt-20"
                        type="email"
                        value={email}
                        placeholder="Type your email:"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button
                        className={`mt-20 linear mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 ${loading
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200'
                            }`}
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Sending...' : 'Send Reset Link'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
