import React from "react";

const Button = ({ label, onClick, className = '' }) => (
    <div className="flex justify-center">
        <button
            onClick={onClick}
            type="button"
            className={`bg-brand-500 dark:bg-brand-400 text-white px-6 py-2 rounded-xl shadow-md hover:bg-brand-600 hover:dark:bg-brand-600 transition-all duration-300 w-30 ${className}`}
        >
            {label}
        </button>
    </div>
);

export default Button;
