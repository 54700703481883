import Card from "components/card";
import CustomCountUp from './CustomCountUp';

const Widget = ({ icon, title, subtitle, isMoney }) => {
    return (
        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                    <span className="flex items-center text-brand-500 dark:text-white">
                        {icon}
                    </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    {isMoney ? '\u20AC' : null}
                    <CustomCountUp
                        start={0} // Starting value
                        end={parseFloat(subtitle)} // Ending value
                        duration={1000} // Duration in milliseconds
                        decimals={isMoney ? 2 : 0} // Number of decimal places
                        separator="," // Thousands separator
                    />
                </h4>
            </div>
        </Card>
    );
};

export default Widget;
