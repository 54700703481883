import React from 'react';

const PageSizeSelector = ({ pageSize, setPageSize, setCurrentPage }) => {
    return (
        <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
                setPageSize(Number(e.target.value));
                setCurrentPage(0);
            }}
            className="border rounded-md p-1 bg-white dark:bg-navy-800 text-gray-800 dark:text-gray-200 border-gray-300 dark:border-gray-600"
        >
            {/*<option value={5}>5</option>*/}
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
        </select>
    );
};

export default PageSizeSelector;
