import axios from 'axios';

const getAuthTokenFromCookies = () => {
    return document.cookie
        .split('; ')
        .find(row => row.startsWith('authToken='))
        ?.split('=')[1];
};
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

apiClient.interceptors.request.use(
    (config) => {
        const token = getAuthTokenFromCookies();

        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            if (window.location.pathname !== '/auth/sign-in') {
                window.location.href = '/auth/sign-in';
            }
        }
        return Promise.reject(error);
    }
);

export default apiClient;