import React from "react";
import PieChart from "./PieChart";
import Card from "components/card";

const PieChartCard = ({ AdministrativePercentage, BrokerPercentage, SitePercentage }) => {
    const labels = ["Administrative", "Broker", "Site"];
    const colors = ["#4318FF", "#6AD2FF", "#FF754C"];
    const pieChartData = [
        parseFloat(AdministrativePercentage) || 0,
        parseFloat(BrokerPercentage) || 0,
        parseFloat(SitePercentage) || 0,
    ];

    return (
        <Card extra="rounded-[20px] p-3">
            <div className="flex flex-row justify-between px-3 pt-2">
                <div>
                    <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                        Total Reservations
                    </h4>
                </div>
            </div>

            <div className="mb-auto flex h-[300px] w-full items-center justify-center">
                {(pieChartData[0] || pieChartData[1] || pieChartData[2]) ? (
                    <PieChart labels={labels} colors={colors} series={pieChartData} />
                ) : (
                    <p className="text-gray-500 dark:text-gray-400">No data available</p>
                )}
            </div>

            <div className="flex flex-row justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:bg-navy-700 dark:shadow-none">
                <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center">
                        <div className="h-2 w-2 rounded-full" style={{ backgroundColor: colors[0] }} />
                        <p className="ml-1 text-sm font-normal text-gray-600 dark:text-white">{labels[0]}</p>
                    </div>
                    <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                        {AdministrativePercentage}%
                    </p>
                </div>

                <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

                <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center">
                        <div className="h-2 w-2 rounded-full" style={{ backgroundColor: colors[1] }} />
                        <p className="ml-1 text-sm font-normal text-gray-600 dark:text-white">{labels[1]}</p>
                    </div>
                    <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                        {BrokerPercentage}%
                    </p>
                </div>

                <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

                <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center">
                        <div className="h-2 w-2 rounded-full" style={{ backgroundColor: colors[2] }} />
                        <p className="ml-1 text-sm font-normal text-gray-600 dark:text-white">{labels[2]}</p>
                    </div>
                    <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                        {SitePercentage}%
                    </p>
                </div>
            </div>
        </Card>
    );
};

export default PieChartCard;
