import React from "react";
import Chart from "react-apexcharts";

// Function to get pie chart options with enhanced styling
export const getPieChartOptions = (labels, colors) => {
    return {
        labels: labels,
        chart: {
            type: "pie",
            width: "100%",
            height: "100%",
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
            },
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                offsetX: 0,
                offsetY: 0,
                dataLabels: {
                    offset: -10, // Moves labels slightly inside each slice
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        colors: ['#ffffff'], // White labels for contrast
                    },
                },
                donut: {
                    size: '65%',
                    labels: {
                        show: true,
                        name: {
                            fontSize: '16px',
                            color: '#e2e8f0', // Light text for dark theme
                        },
                        value: {
                            fontSize: '24px',
                            color: '#ffffff',
                            formatter: (val) => `${val}%`,
                        },
                    },
                },
            },
        },
        colors: colors,
        tooltip: {
            enabled: true,
            theme: "dark",
            style: {
                fontSize: "12px",
                backgroundColor: "#1e293b", // Navy background for tooltip
                color: "#e2e8f0",           // Light text color for tooltip
            },
            y: {
                formatter: (val) => `${val}%`,
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '14px',
            labels: {
                colors: '#e2e8f0', // Navy-themed label color
            },
            markers: {
                width: 10,
                height: 10,
                radius: 5, // Rounded markers
            },
            itemMargin: {
                horizontal: 10,
                vertical: 5,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };
};

const PieChart = ({ series, labels, colors }) => {
    const options = getPieChartOptions(labels, colors);

    return (
        <Chart
            options={options}
            series={series}
            type="pie"
            width="100%"
            height="300px"
        />
    );
};

export default PieChart;
