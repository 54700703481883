import React, { useEffect, useState } from "react";
import Card from '../../../components/card';
import Button from "./components/Button";
import ReservationFormStepOne from "./components/ReservationFormStepOne";
import ReservationFormStepTwo from "./components/ReservationFormStepTwo";
import Modal from '../../../components/Modal/Modal';
import apiClient from './../axiosInstance';
import { getDefaultDateTime } from './components/Helper.js';


const MakeReservationOverview = () => {
    const [cars, setCars] = useState([]);
    const [locations, setLocations] = useState([]);
    const [reservationDetails, setReservationDetails] = useState({
        pickupDatetime: getDefaultDateTime(),
        returnDatetime: getDefaultDateTime(),
        pickupLocation: null,
        dropoffLocation: null,
        name: "",
        surname: "",
        email: "",
        phone: "",
        flight: "",
        price: 0,
        inclusions: "",
        age: 40,
        selectedCar: null,
    });

    const [error, setError] = useState({
        pickupLocation: false,
        dropoffLocation: false,
        selectedCar: false,
    });

    const [step, setStep] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: "", content: "" });

    useEffect(() => {
        const fetchCarsAndStations = async () => {
            try {
                const response = await apiClient.get('/cars-stations');
                setCars(response.data.cars);
                setLocations(response.data.stations);
            } catch (err) {
                console.error('Error fetching cars and locations:', err);
            }
        };

        fetchCarsAndStations();
    }, []);

    const handleNextStep = (e) => {
        e.preventDefault();
        const { pickupLocation, dropoffLocation, selectedCar, pickupDatetime, returnDatetime } = reservationDetails;

        const pickupTimestamp = new Date(pickupDatetime).getTime();
        const returnTimestamp = new Date(returnDatetime).getTime();

        const newErrorState = {
            pickupDatetime: !pickupDatetime,
            returnDatetime: !returnDatetime || (returnTimestamp <= pickupTimestamp),
            pickupLocation: !pickupLocation,
            dropoffLocation: !dropoffLocation,
            selectedCar: !selectedCar,
        };

        setError(newErrorState);

        if (
            pickupLocation &&
            dropoffLocation &&
            selectedCar &&
            pickupDatetime &&
            returnDatetime &&
            returnTimestamp > pickupTimestamp
        ) {
            setStep(2);
        } else {
            console.log("Please fill out all required fields and ensure that the return date is after the pickup date.");
        }
    };

    const handlePreviousStep = (e) => {
        e.preventDefault();
        setStep(1);
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();
        const { pickupDatetime, returnDatetime, pickupLocation, dropoffLocation, selectedCar } = reservationDetails;

        if (!pickupDatetime || !returnDatetime || !pickupLocation || !dropoffLocation || !selectedCar) {
            console.log("Please fill out all required fields.");
            return;
        }

        try {
            await apiClient.post('/makereservation', reservationDetails);

            setModalContent({
                title: "Success",
                content: "Reservation inserted successfully!"
            });
            setIsModalOpen(true);



            setReservationDetails({
                name: "",
                surname: "",
                email: "",
                phone: "",
                flight: "",
                pickupDatetime: null,
                returnDatetime: null,
                pickupLocation: null,
                dropoffLocation: null,
                price: 0,
                inclusions: "",
                age: 40,
                selectedCar: null,
            });
            setStep(1);

        } catch (err) {
            const errorMessage = err.response?.data?.error || 'Error submitting reservation';
            console.error(errorMessage);
        }
    };

    return (
        <div className="flex w-full md:w-3/4 lg:w-3/4 md:ml-20 lg:ml-30 flex-col p-4">
            <Card extra="p-20">
                <form onSubmit={step === 1 ? handleNextStep : handleFinalSubmit}>
                    {step === 1 && (
                        <ReservationFormStepOne
                            reservationDetails={reservationDetails}
                            setReservationDetails={setReservationDetails}
                            cars={cars}
                            locations={locations}
                            error={error}
                            setError={setError}
                            handleNextStep={handleNextStep}
                        />
                    )}
                    {step === 2 && (
                        <>
                            <ReservationFormStepTwo
                                reservationDetails={reservationDetails}
                                handleChange={(e) => {
                                    const { name, value } = e.target;
                                    setReservationDetails((prevDetails) => ({
                                        ...prevDetails,
                                        [name]: value,
                                    }));
                                }}
                                error={error}
                            />
                            <div className="flex flex-row justify-between mb-4 mt-10 gap-4">
                                <Button label="Previous" onClick={handlePreviousStep} />
                                <Button label="Submit" onClick={handleFinalSubmit} />
                            </div>

                        </>
                    )}
                </form>
            </Card>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
            />
        </div>
    );
};

export default MakeReservationOverview;
