export const formatDate = (dateValue) => {
    const date = new Date(dateValue);

    const day = String(date.getUTCDate()).padStart(2, '0');

    const month = String(date.getUTCMonth() + 1).padStart(2, '0');

    const year = String(date.getUTCFullYear()).slice(-2);

    const hours = String(date.getUTCHours()).padStart(2, '0');

    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};




export const calculateTotalPrice = (price, commission) => {
    return `\u20AC ${(price + commission).toFixed(2)}`;
};

export const getStationInfo = (stationId, stationsData) => {
    const station = stationsData.find((station) => station.StationID === stationId);
    return station ? station : { StationID: stationId, Name: 'Unknown Location' };
};

export const getCarInfo = (CarID, carData) => {
    const car = carData.find((car) => car.CarID === CarID);
    return car ? car : { CarID: CarID, Name: 'Unknown Car' };
};