function InputField(props) {
    const { label, id, extra, type, placeholder, variant, value, onChange, disabled, name } = props;

    return (
        <div className={`${extra}`}>
            <label
                htmlFor={id}
                className={`text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"}`}
            >
                {label}
            </label>
            <input
                disabled={disabled}
                name={name}
                type={type}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled
                    ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                    : `border-gray-200 dark:!border-red dark:text-white ${extra}` // dark:!border-white/10
                    }`}
            />

        </div>
    );
}

export default InputField;
