import React from 'react';
import { flexRender } from '@tanstack/react-table';

const TableBody = ({ currentRows }) => {
    return (
        <tbody>
            {currentRows.map((row, rowIndex) => (
                <tr
                    key={row.id}
                    className={rowIndex % 2 === 0
                        ? 'bg-white dark:bg-navy-800 hover:bg-gray-300 hover:dark:bg-navy-600'
                        : 'bg-gray-100 dark:bg-navy-700 hover:bg-gray-300 hover:dark:bg-navy-600'}
                >
                    {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="min-w-[80px] border-white/0 py-3 pr-4 pl-2 dark:border-gray-600">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
};

export default TableBody;
