import React, { useEffect } from "react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

function Modal({
    isOpen,
    onClose,
    title,
    content,
    children,
    footer,
    className,
    headerIcons = [],
}) {
    useEffect(() => {
        if (!isOpen) return;

        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex items-center justify-center z-50`}
            onClick={onClose}
        >
            <div
                className="bg-gray-400 dark:bg-gray-900 text-navy-700 dark:text-white rounded-2xl shadow-2xl w-full max-w-2xl mx-4 p-6 relative transform transition-transform duration-300 ease-out"
                onClick={(event) => event.stopPropagation()}
            >
                <button
                    className="absolute top-4 right-4 text-black hover:text-red-500 text-2xl focus:outline-none"
                    onClick={onClose}
                >
                    <XMarkIcon className="h-6 w-6" />
                </button>

                {title && (
                    <div className="mb-6 flex items-center justify-center space-x-2">
                        {title && (
                            <div className="mb-6 flex items-center justify-center space-x-2">
                                {title.includes("Success") && (
                                    <CheckCircleIcon className="h-6 w-6 text-green-500" />
                                )}
                                <h2 className={`text-3xl font-extrabold text-center ${title.includes("Success") ? 'text-green-500' : ''}`}>
                                    {title}
                                </h2>
                                {title.includes("Success") && (
                                    <CheckCircleIcon className="h-6 w-6 text-green-500" />
                                )}
                            </div>
                        )}

                        {headerIcons.length > 0 && (
                            <div className="flex justify-center mt-4 space-x-4">
                                {headerIcons.map((IconComponent, index) => (
                                    <span key={index} className="text-brand-500">
                                        <IconComponent className="h-6 w-6" />
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                <div className="py-4 space-y-4">
                    {content || children || <div className="h-4"></div>}
                </div>

                {footer && (
                    <div className="mt-8 w-full">
                        {footer}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Modal;
