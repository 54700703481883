import React from 'react';
import GenericDropdown from './GenericDropdown';
import InputField from './Fields';
import Button from './Button';

const ReservationFormStepOne = ({
    reservationDetails,
    setReservationDetails,
    cars,
    locations,
    error,
    setError,
    handleNextStep,
}) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setReservationDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
           {/* <DateTime/>*/}
            <InputField
                label={
                    <>
                        Pickup Date & Time
                        <span className="text-red-500 ml-1">*</span>
                    </>
                }
                type="datetime-local"
                name="pickupDatetime"
                value={reservationDetails.pickupDatetime || ''}
                setError={setError}
                onChange={handleChange}
                error={error.pickupDatetime}
                className="max-w-xs"
            />
            <InputField
                label={
                    <>
                        Return Date & Time
                        <span className="text-red-500 ml-1">*</span>
                    </>
                }
                type="datetime-local"
                name="returnDatetime"
                value={reservationDetails.returnDatetime || ''}
                setError={setError}
                onChange={handleChange}
                error={error.returnDatetime}
                className="max-w-xs"
            />
            <GenericDropdown
                items={locations}
                reservationDetails={reservationDetails}
                setReservationDetails={setReservationDetails}
                setError={setError}
                label="Pickup"
                error={error.pickupLocation}
                itemType="location"
                selectedItem={reservationDetails.pickupLocation}
            />
            <GenericDropdown
                items={locations}
                reservationDetails={reservationDetails}
                setReservationDetails={setReservationDetails}
                setError={setError}
                label="Drop-off"
                error={error.dropoffLocation}
                itemType="location"
                selectedItem={reservationDetails.dropoffLocation}
            />
            <GenericDropdown
                items={cars}
                reservationDetails={reservationDetails}
                setReservationDetails={setReservationDetails}
                setError={setError}
                error={error.selectedCar}
                itemType="car"
                selectedItem={reservationDetails.selectedCar}
            />
            <div className="mt-20">
                <Button label="Next" onClick={handleNextStep} />
            </div>
        </div>
    );
};

export default ReservationFormStepOne;
