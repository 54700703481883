import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

function useOutsideAlerter(refs, setX) {
    useEffect(() => {
        function handleClickOutside(event) {
            // Check if the event target is inside any of the refs
            const isOutside = refs.every(
                (ref) => ref.current && !ref.current.contains(event.target)
            );
            if (isOutside) {
                setX(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            //document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refs, setX]);
}

const Dropdown = (props) => {
    const { button, children, classNames } = props;
    const wrapperRef = useRef(null);
    const dropdownRef = useRef(null); // New ref for the dropdown content
    const [openWrapper, setOpenWrapper] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState({});

    // Pass both refs to the hook
    useOutsideAlerter([wrapperRef, dropdownRef], setOpenWrapper);

    const toggleDropdown = () => {
        setOpenWrapper((prev) => !prev);
    };

    useEffect(() => {
        if (openWrapper && wrapperRef.current) {
            const rect = wrapperRef.current.getBoundingClientRect();
            setDropdownStyle({
                position: "absolute",
                top: rect.bottom + window.scrollY,
                right: window.innerWidth - rect.right - window.scrollX,
                zIndex: 9999,
            });
        }
    }, [openWrapper]);

    return (
        <div ref={wrapperRef} className="relative flex">
            <div className="flex" onMouseDown={toggleDropdown}>
                {button}
            </div>
            {openWrapper &&
                ReactDOM.createPortal(
                    <div
                        ref={dropdownRef}
                        className={`${classNames} transition-all duration-300 ease-in-out`}
                        style={dropdownStyle}
                    >
                        {children}
                    </div>,
                    document.getElementById("portal-root")
                )}
        </div>
    );
};

export default Dropdown;
