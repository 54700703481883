import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";

export default function Admin(props) {
    const { ...rest } = props;
    const location = useLocation();
    const [open, setOpen] = React.useState(() => window.innerWidth >= 1200); // Closed by default on smaller screens
    const [currentRoute, setCurrentRoute] = React.useState("Dashboard");
    const sidebarRef = React.useRef(null);

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setOpen(false); // Close sidebar on smaller screens
            } else {
                setOpen(true); // Open sidebar on larger screens
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useEffect(() => {
        getActiveRoute(routes);
    }, [location.pathname]);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && window.innerWidth <= 1200) {
                setOpen(false); 
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sidebarRef, setOpen]);


    const getActiveRoute = (routes) => {
        let activeRoute = "Dashboard";
        for (let i = 0; i < routes.length; i++) {
            if (
                window.location.href.indexOf(
                    routes[i].layout + "/" + routes[i].path
                ) !== -1
            ) {
                setCurrentRoute(routes[i].name);
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (
                window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
            ) {
                return routes[i].secondary;
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route path={`/${prop.path}`} element={prop.component} key={key} />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <div className="flex h-full w-full">
            <Sidebar
                ref={sidebarRef} // Pass ref to Sidebar
                open={open}
                onClose={() => setOpen(false)}
            />
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                <main
                    className={`mx-2 h-full flex-none transition-all md:pr-2 xl:ml-[250px]`} // Changed 313px
                >
                    <div className="h-full">
                        <Navbar
                            onOpenSidenav={() => setOpen(true)}
                            logoText={"Karpadu React"}
                            brandText={currentRoute}
                            secondary={getActiveNavbar(routes)}
                            {...rest}
                        />
                        <div className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                            <Routes>
                                {getRoutes(routes)}

                                <Route
                                    path="/"
                                    element={<Navigate to="/admin/dashboard" replace />}
                                />
                            </Routes>
                        </div>
                        <div className="p-3">
                            <Footer />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
