import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function SignIn() {
    const { login, user } = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [keepLoggedIn, setKeepLoggedIn] = useState(false);


    useEffect(() => {
        if (user) {
            navigate("/admin/dashboard");
        }
    }, [user, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        if (!email) {
            setError("Email is required.");
            setLoading(false);
            return;
        }
        if (!password) {
            setError("Password is required.");
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/sign-in`,
                { email, password, keepLoggedIn },
                { withCredentials: true }
            );

            if (response.status === 200) {
                const { CompanyName } = response.data;
                login(CompanyName);
                setEmail("");
                setPassword("");
            } else {
                setError(response.data.message || "Sign-in failed. Please try again.");
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("An error occurred. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleForgot = (e) => {
        e.preventDefault();
        navigate("/auth/forgot-password");
    };


    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Sign In
                </h4>
                <div className="mb-6 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                </div>

                {error && (
                    <div className="mb-4 text-red-500 text-sm">{error}</div>
                )}

                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Email*"
                    placeholder="mail@karpadu.com"
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <div className="mb-4 flex items-center justify-between px-2">
                    <div className="flex items-center">
                        <Checkbox
                            checked={keepLoggedIn}
                            onChange={() => setKeepLoggedIn(!keepLoggedIn)}
                        />
                        <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                            Keep me logged in
                        </p>
                    </div>
                    <button
                        className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white hover:underline"
                        onClick={handleForgot}
                    >
                        Forgot Password?
                    </button>
                </div>

                <button
                    className={`linear mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 ${loading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        }`}
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? "Signing In..." : "Sign In"}
                </button>
            </div>
        </div>
    );
}
