// AuthContext.jsx
import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import axios from "axios";

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const checkSession = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-token`, { withCredentials: true });
            if (response.status === 200 && response.data.authenticated) {
                setUser(response.data.user.CompanyName);
               
            } else {
                setUser(null);
            }
        } catch (error) {
            setUser(null);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        checkSession();
    }, [checkSession]);

    const login = useCallback((companyName) => {
        setUser(companyName);
        localStorage.setItem("lastPath", "/admin/dashboard");
    }, []);

    const logout = useCallback(async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/sign-out`, {}, { withCredentials: true });
        setUser(null);
    }, []);

    return (
        <AuthContext.Provider value={{ login, logout, loading, user }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
