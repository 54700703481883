import React from 'react';
import { flexRender } from '@tanstack/react-table';

const TableHeader = ({ headerGroup }) => {
    const toggleSort = (header) => {
        // Get the current sort state of the column
        const currentSort = header.column.getIsSorted();
        // Toggle between 'asc' and 'desc'
        const newSort = currentSort === 'asc' ? 'desc' : 'asc';

        // Update the sorting state, passing multi=false to reset other columns
        header.column.toggleSorting(newSort === 'desc', false);
    };

    return (
        <tr key={headerGroup.id} className="!border-px !border-gray-400">
            {headerGroup.headers.map((header) => {
                // Check if the column can be sorted
                const canSort = header.column.getCanSort();

                // Get the current sort status
                const sortStatus = header.column.getIsSorted();
                const isSortedAsc = sortStatus === 'asc';
                const isSortedDesc = sortStatus === 'desc';

                return (
                    <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={canSort ? () => toggleSort(header) : undefined}
                        className={`border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start ${canSort ? 'cursor-pointer' : ''}`}
                    >
                        <div className="flex items-center text-xs text-gray-200">
                            {/* Sorting icons positioned before the column name */}
                            {canSort && (
                                <div className="relative mr-2">
                                    {isSortedAsc && (
                                        <i className="fas fa-arrow-up text-green-500 mb-1 mr-1"></i>
                                    )}
                                    {isSortedDesc && (
                                        <i className="fas fa-arrow-down text-red-500 mb-1 mr-1"></i>
                                    )}
                                    {!isSortedAsc && !isSortedDesc && (
                                        <i className="fas fa-sort text-gray-400 transition-opacity duration-300"></i>
                                    )}
                                </div>
                            )}
                            {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                    </th>

                );
            })}
        </tr>
    );
};

export default TableHeader;
