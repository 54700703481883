import React from 'react';
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate, getStationInfo, getCarInfo } from './Utils';
import StatusIndicator from './StatusIndicator';
import Dropdown from './../Dropdown/DotsDropdown';
import { FaEye, FaDownload, FaTimes, FaEdit } from 'react-icons/fa';
import { createDetailedContent } from './../../../../../components/Modal/RsvModalgen';
import { generateReservationPDF } from './pdfGenerator';
import { fetchExtras }  from './fetchExtras';
import { checkCancel } from './checkCancel';
const columnHelper = createColumnHelper();

export const createColumns = (stationsData, carsData, toggleDropdown, selectedRow, setIsModalOpen, setModalContent) => [

    {
        accessor: "CreatedAt",
        header: "Created At",
        cell: (info) => formatDate(info.getValue()),
    },
    {
        accessor: (row) => `${row.Name} ${row.Surname}`,
        header: "Full Name",
        cell: (info) => info.getValue(),
    },
    {
        accessor: (row) => getStationInfo(row.PickupLocation, stationsData),
        header: "Pick Up Location",
        enableSorting: false, 
        cell: (info) => {
            const stationInfo = info.getValue();
            return stationInfo.Name;
        },
    },
    {
        accessor: (row) => getStationInfo(row.ReturnLocation, stationsData),
        header: "Drop Off Location",
        enableSorting: false, 
        cell: (info) => {
            const stationInfo = info.getValue();
            return stationInfo.Name;
        },
    },
    {
        accessor: "PickupDatetime",
        header: "Pickup Date",
        cell: (info) => formatDate(info.getValue()),
    },
    {
        accessor: "ReturnDatetime",
        header: "Return Date",
        cell: (info) => formatDate(info.getValue()),
    },
    {
        accessor: "Status",
        header: "Status",
        cell: (info) => (
            <div className="text-sm font-bold text-navy-700 dark:text-white">
                <StatusIndicator status={info.getValue()} />
            </div>
        ),
    },
    {
        accessor: (row) => getCarInfo(row.CarID, carsData),
        header: "Car",
        enableSorting: false, 
        cell: (info) => {
            const carInfo = info.getValue();
            return `${carInfo.Make} ${carInfo.Model}`;
        },
    }, {
        accessor: "Channel",
        header: "Channel",
        cell: (info) => info.getValue(),
    },
    {
        accessor: "Price",
        header: "Price",
        cell: (info) => info.getValue(),
    },
    {
        id: 'details',
        header: 'Actions',
        enableSorting: false, 
        cell: ({ row }) => (
            <div className="flex justify-end relative">
                <Dropdown
                    button={
                        <button
                            onClick={() => toggleDropdown(row.original)}
                            className="group flex flex-col items-center justify-center p-0 w-8"
                        >
                            <div className="w-1 h-1 bg-gray-500 group-hover:bg-brand-500 group-hover:dark:bg-brand-400 rounded-full mb-1"></div>
                            <div className="w-1 h-1 bg-gray-500 group-hover:bg-brand-500 group-hover:dark:bg-brand-400 rounded-full mb-1"></div>
                            <div className="w-1 h-1 bg-gray-500 group-hover:bg-brand-500 group-hover:dark:bg-brand-400 rounded-full"></div>
                        </button>
                    }
                    classNames="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-xl hover:rounded-xl dark:rounded-xl shadow-lg"
                    animation="origin-top-right transition-all duration-300 ease-in-out"
                >
                    <ul>
                        {/* View Details Option */}
                        <li className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer dark:text-white flex items-center dark:bg-navy-700 rounded-t-xl"
                            onClick={async () => {
                                const extras = await fetchExtras(row.original.ReservationID);
                                setModalContent({
                                    title: "Details",
                                    content: createDetailedContent(row.original, stationsData, carsData, extras),
                                    row: row.original,
                                });
                                setIsModalOpen(true);
                            }}>
                            <FaEye className="mr-2" />
                            View Details
                        </li>
                        {/* Download PDF Option */}
                        <li
                            className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer dark:text-white flex items-center dark:bg-navy-700"
                            onClick={ async () => {
                                const extras = await fetchExtras(row.original.ReservationID);
                                generateReservationPDF(row.original, stationsData, carsData, extras);
                            }}
                        >
                            <FaDownload className="mr-2" />
                            Download PDF
                        </li>
                        {/* Edit Reservation Option */}
                        <li
                            className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer dark:text-white flex items-center dark:bg-navy-700"
                        >
                            <FaEdit className="mr-2" />
                            Edit Reservation
                        </li>
                        <li className="p-2 hover:bg-gray-200 hover:dark:bg-navy-600 cursor-pointer text-red-500 flex items-center dark:bg-navy-700 rounded-b-xl"
                            onClick={() => checkCancel(row.original, setIsModalOpen, setModalContent)}>
                            <FaTimes className="mr-2" />
                            Cancel
                        </li>
                    </ul>
                </Dropdown>
            </div>
        ),
    }
].map((col) => {
    // Determine if the accessor is a function or a string
    const accessorFn = typeof col.accessor === 'function' ? col.accessor : (row) => row[col.accessor];

    return columnHelper.accessor(accessorFn, {
        id: col.id || col.header.replace(/\s+/g, '').toLowerCase(),
        header:
            col.header === 'Download' ? null : (
                <p className="text-sm font-bold text-gray-600 dark:text-white">{col.header}</p>
            ),
        cell: (info) => (
            <div className="text-sm text-navy-700 dark:text-white ">{col.cell(info)}</div>
        ),
        enableSorting: col.enableSorting !== undefined ? col.enableSorting : true, // Use enableSorting from column definition
    });
});
