/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import Card from 'components/card';
import PaginationControls from './../PaginationControls/PaginationControls';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { createColumns } from './../Helpers/tableColumns';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import FilterSection from './FilterSection';
import Modal from './../../../../../components/Modal/Modal';

function ReservationTable(props) {
    const { tableData, stationsData, carsData } = props;
    const [sorting, setSorting] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const dropdownRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', content: '', row: null });
    const [selectedPickupStations, setSelectedPickupStations] = useState([]);
    const [selectedReturnStations, setSelectedReturnStations] = useState([]);
    const [selectedCars, setSelectedCars] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);

    const toggleDropdown = (row) => {
        setSelectedRow(selectedRow?.id === row.id ? null : row);
    };

    const columns = createColumns(
        stationsData,
        carsData,
        toggleDropdown,
        null,
        setIsModalOpen,
        setModalContent
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSelectedRow(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredData = useMemo(() => {
        let data = [...tableData];

        // Filter by selected pickup stations
        if (selectedPickupStations.length > 0) {
            data = data.filter((row) => {
                const stationInfo = stationsData.find(
                    (station) => station.StationID === row.PickupLocation
                );
                return stationInfo && selectedPickupStations.includes(stationInfo.Name);
            });
        }

        // Filter by selected return stations
        if (selectedReturnStations.length > 0) {
            data = data.filter((row) => {
                const stationInfo = stationsData.find(
                    (station) => station.StationID === row.ReturnLocation
                );
                return stationInfo && selectedReturnStations.includes(stationInfo.Name);
            });
        }

        // Filter by selected cars using CarID
        if (selectedCars.length > 0) {
            data = data.filter((row) => selectedCars.includes(row.CarID));
        }
        // Filter by selected statuses
        if (selectedStatuses.length > 0) {
            data = data.filter((row) => selectedStatuses.includes(row.Status));
        }

        return data;
    }, [
        tableData,
        selectedPickupStations,
        selectedReturnStations,
        selectedCars,
        selectedStatuses,
        stationsData,
        carsData,
    ]);


    // Reset current page when filtered data changes
    useEffect(() => {
        setCurrentPage(0);
    }, [filteredData]);

    const table = useReactTable({
        data: filteredData,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const pageCount = Math.ceil(filteredData.length / pageSize);
    const currentRows = table
        .getRowModel()
        .rows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    useEffect(() => {
        const loadData = async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setIsLoading(false);
        };
        loadData();
    }, []);

    return (
        <div className="relative flex w-full flex-col gap-5 mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
            {/* Overlay that appears when modal is open */}
            {isModalOpen && <div className="fixed inset-0 bg-black opacity-50 z-40"></div>}

            <Card extra={'w-full h-full sm:overflow-auto px-3'}>
                {isLoading ? (
                    <div className="text-center py-5">Loading...</div>
                ) : (
                    <>
                        <FilterSection
                            selectedPickupStations={selectedPickupStations}
                            setSelectedPickupStations={setSelectedPickupStations}
                            selectedReturnStations={selectedReturnStations}
                            setSelectedReturnStations={setSelectedReturnStations}
                            selectedCars={selectedCars}
                            setSelectedCars={setSelectedCars}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            setCurrentPage={setCurrentPage}
                            stationsData={stationsData}
                            carsData={carsData}
                            selectedStatuses={selectedStatuses}
                            setSelectedStatuses={setSelectedStatuses}
                        />
                        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
                            <table className="w-full">
                                <thead>
                                    {table.getHeaderGroups().map((headerGroup) => (
                                        <TableHeader key={headerGroup.id} headerGroup={headerGroup} />
                                    ))}
                                </thead>
                                <TableBody currentRows={currentRows} />
                            </table>
                        </div>
                        <PaginationControls
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageCount={pageCount}
                        />
                    </>
                )}
            </Card>

            {/* Modal component */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
                row={modalContent.row}
                stationsData={stationsData}
                className="bg-brand-500"
            />

        </div>
    );
}

export default ReservationTable;
