import React, { useEffect, useRef, useState } from 'react';

const CustomDropdown = ({
    items = [],
    reservationDetails = {},
    setReservationDetails,
    label,
    error,
    setError,
    itemType // 'location' or 'car'
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(
        itemType === 'location' ? reservationDetails[label === "Pickup" ? "pickupLocation" : "dropoffLocation"] : reservationDetails.selectedCar
    );
    const dropdownRef = useRef(null);

    const handleSelect = (item) => {
        setSelectedItem(item);
        setIsOpen(false);

        setReservationDetails((prevDetails) => ({
            ...prevDetails,
            [itemType === 'location' ? (label === "Pickup" ? "pickupLocation" : "dropoffLocation") : "selectedCar"]: item,
        }));

        setError((prevError) => ({
            ...prevError,
            [itemType === 'location' ? (label === "Pickup" ? "pickupLocation" : "dropoffLocation") : "selectedCar"]: false,
        }));
    };

    useEffect(() => {
        // Function to close the dropdown if clicked outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Add event listener for mousedown
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative mt-4" ref={dropdownRef}>
            <label className="text-sm font-semibold">
                {itemType === 'location' ? `${label} Location` : 'Selected Car'}
                <span className="text-red-500"> *</span>
            </label>
            <div className={`rounded-xl border ${error ? 'border-red-500' : 'border-gray-300'} max-w-xs w-full`}>
                <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="w-full p-2 rounded-xl bg-white dark:bg-navy-800 flex justify-between items-center"
                >
                    <span className="flex items-center">
                        {selectedItem ? (
                            itemType === 'location' ? (
                                selectedItem.Name
                            ) : (
                                <>
                                    <img
                                        src={selectedItem.imageURL}
                                        alt={`${selectedItem.Make} ${selectedItem.Model} ${selectedItem.Year}`}
                                        className="w-8 h-8 rounded-lg mr-2"
                                    />
                                    <span>{`${selectedItem.Make} ${selectedItem.Model} ${selectedItem.Year}`}</span>
                                </>
                            )
                        ) : (
                            `Select ${itemType === 'location' ? label : 'Car'}`
                        )}
                    </span>
                </button>

                {isOpen && (
                    <ul className="absolute z-10 mt-1 w-full bg-gray-100 rounded-xl shadow-lg border border-gray-300 max-h-60 overflow-auto dark:bg-navy-800">
                        {items.length > 0 ? (
                            items.map(item => (
                                <li
                                    key={item[itemType === 'location' ? 'StationID' : 'CarID']}
                                    onClick={() => handleSelect(item)}
                                    className="cursor-pointer p-2 hover:bg-gray-200 hover:dark:bg-navy-900 rounded-xl flex items-center"
                                >
                                    {itemType === 'car' && item.imageURL && (
                                        <img
                                            src={item.imageURL}
                                            alt={`${item.Make} ${item.Model}`}
                                            className="w-16 h-16 rounded-lg mr-2"
                                        />
                                    )}
                                    <div className="flex flex-col">
                                        {itemType === 'car' ? (
                                            <>
                                                <div>
                                                    <strong>{item.Make}</strong> {' '}
                                                    <strong>{item.Model}</strong> {' '}
                                                    <strong>{item.Year}</strong>
                                                </div>
                                                <div className="text-sm">
                                                    {item.Transmission} | {item.FuelType}
                                                </div>
                                            </>
                                        ) : (
                                            <div>{item.Name}</div>
                                        )}
                                    </div>
                                </li>
                            ))
                        ) : (
                            <li className="p-2">No {itemType === 'location' ? 'locations' : 'cars'} available</li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default CustomDropdown;
