import React, { useEffect, useState } from 'react';

const CustomCountUp = ({ start, end, duration, decimals, separator }) => {
    const [count, setCount] = useState(start);
    const [isCounting, setIsCounting] = useState(true);

    useEffect(() => {
        if (!isCounting) return;

        const increment = (end - start) / (duration / 100);
        let current = start;
        const interval = setInterval(() => {
            current += increment;

            // Stop if we exceed the end value
            if (current >= end) {
                clearInterval(interval);
                current = end; // Ensure it ends exactly at end
                setIsCounting(false);
            }
            setCount(current);
        }, 100);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [end, start, duration, isCounting]);

    // Function to format the number with decimals and thousands separator
    const formatNumber = (number) => {
        const formatted = number.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        return formatted;
    };

    return <span>{formatNumber(count)}</span>;
};

export default CustomCountUp;
