import React from "react";

import SignIn from "views/auth/SignIn";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";

import MainDashboard from "views/admin/dashboard";
import DataTables from "views/admin/reservations";
import MakeReservation from "views/admin/makereservation";
import Cars from "views/admin/cars";
import Prices from "views/admin/prices";
import Profile from "views/admin/profile";
import CarsOverview from "views/admin/caroverview";
import { MdHome, MdAttachMoney, MdBarChart, MdPerson, MdDirectionsCar, MdEvent, MdCalendarMonth } from "react-icons/md";

const routes = [
  {
        name: "Dashboard",
        layout: "/admin",
        path: "dashboard",
        icon: <MdHome className="h-6 w-6" />,
        component: <MainDashboard />,
  },
  {
        name: "Reservations",
        layout: "/admin",
        icon: <MdBarChart className="h-6 w-6" />,
        path: "reservations",
        component: <DataTables />,
  },
    {
        name: "Make Reservation",
        layout: "/admin",
        icon: <MdEvent className="h-6 w-6" />,
        path: "makereservation",
        component: <MakeReservation />,
    },
    {
        name: "Cars",
        layout: "/admin",
        path: "cars",
        icon: <MdDirectionsCar className="h-6 w-6" />,
        component: <Cars />,
        sidebar: true,
    },
    {
        name: "Cars Overview",
        layout: "/admin",
        path: "caroverview",
        icon: <MdCalendarMonth className="h-6 w-6" />,
        component: <CarsOverview />,
        sidebar: true,
    },
    {
        name: "Prices",
        layout: "/admin",
        path: "prices",
        icon: <MdAttachMoney className="h-6 w-6" />,
        component: <Prices />,
        sidebar: true,
    },
    {
        name: "Profile",
        layout: "/admin",
        path: "profile",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Profile />,
        sidebar: true,
    },
    {
        name: "signin",
        layout: "/auth",
        path: "sign-in",
        component: <SignIn />,
        sidebar: false,
    },
    {
        name: "Forgot Password",
        layout: "/auth",
        path: "forgot-password",
        component: <ForgotPassword />,
        sidebar: false,
    },
    {
        name: "Reset Pasword",
        layout: "/auth",
        path: "reset-password",
        component: <ResetPassword />,
        sidebar: false,
    },
];
export default routes;
