import { useState } from 'react';
import axios from 'axios';
import InputField from "components/fields/InputField";
import { useSearchParams, useNavigate } from 'react-router-dom'; // Import useNavigate

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // Initialize useNavigate
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        const token = searchParams.get('token');

        if (!token) {
            setError('Invalid or missing token. Please request a new password reset.');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match. Please try again.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
                token,
                newPassword: password,
            });
            if (response.status === 200) {
                navigate('/auth/sign-in', { state: { message: 'Password reset successfully. Please log in.' } });
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Reset Password
                </h4>

                <div className="mb-6 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                </div>

                {/* Error Message */}
                {error && (
                    <div
                        className="mb-4 text-red-500 text-sm"
                        aria-live="polite"
                    >
                        {error}
                    </div>
                )}

                <form onSubmit={handleResetPassword}>
                    <InputField
                        className="mt-20"
                        type="password"
                        value={password}
                        placeholder="New Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <InputField
                        className="mt-4"
                        type="password"
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button
                        className={`mt-20 linear mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 ${loading
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200'
                            }`}
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Resetting...' : 'Reset Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
