import React, { forwardRef } from "react";
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import logo from "./../../assets/logo.png";
import logo_white from "./../../assets/logo_white.png";

const Sidebar = forwardRef(({ open, onClose }, ref) => {
    return (
        <div
            ref={ref}
            className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
                }`}
        >
            <span
                className="absolute top-4 right-4 block cursor-pointer xl:hidden"
                onClick={onClose}
            >
                <HiX />
            </span>
            <div className="mx-[30px] mt-[30px] flex items-center">
                <img src={logo} alt="Logo" className="h-10 dark:hidden" />
                <img src={logo_white} alt="Logo White" className="h-10 hidden dark:block" />
            </div>
            <div className="mt-[20px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
            <ul className="mb-auto pt-1">
                <Links routes={routes} />
            </ul>
        </div>
    );
});

export default Sidebar;
